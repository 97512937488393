import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo-small.png"

const IndexPage = ({isDarkMode, dispatch}) => (
  <Layout>
    <SEO title="Home" />
        <h1 className="text-center"><img alt="Rocket" style={{height: "200px"}} src={logo} /></h1>
        <h2 className="text-center mt-5 mb-5">Power up your debt free journey</h2>
    <p>
      <b>The app isn't ready to go just yet, but we can help!</b>
    </p>
    <p>
      We know that knowing where to start is one of the biggest challenges to getting debt free so we’ve launched a debt planning and budgeting tool to get you started…
    </p>

    <div className="row">
        <div className="col-md-4">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h4 className="card-text text-center">Your debt in one place</h4>
              <p className="card-text">No more of this: [INSERT MONKEY COVERING FACE EMOJI] . Tell us about your debt and we’ll build a picture of your current situation.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h4 className="card-text text-center">Make a plan</h4>
              <p className="card-text">Not sure where to start or how much to pay off each month? We’ll do the maths and help you to create a payoff plan that works for you and saves you money</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h4 className="card-text text-center">Create a budget</h4>
              <p className="card-text">One you’ll actually stick to! Create a budget that lets you live your life and gets you debt-free sooner.</p>
            </div>
          </div>
        </div>
    </div>

    <div className="text-center mb-5">
      <Link to="/user/priority-debt" className="btn btn-primary">MAKE A PLAN</Link>
    </div>
    <div className="alert alert-danger">
      Important stuff: Whilst we hope that the tool will be useful for multiple scenarios and every effort has been made to ensure its accuracy, the results should be used as an indication only. This tool may not be suitable if you have a more complex debt scenario and the results do not constitute financial advice.      
    </div>
    <p>
      <b>Debt-advice for the digital age. From Go Fund Yourself</b>
    </p>
    <p>
    On a mission to make personal finance relevant and relatable, Alice Tapper founded Go Fund Yourself; a book, online platform and Instagram community. Online she has become known for her simple money guides and news bulletins which make complex topics digestible and entertaining.       
    </p>
    <p>
    Having spent over 2 years engaging with the financial challenges of her generation, Alice is taking on a new challenge; tackling the debt crisis which last year saw over x-x declared bankrupt.    
    </p>
    <p className="text-center">
      <a href="http://gofundyourself.co" className="btn btn-primary">Learn more</a>
    </p>
  </Layout>
)

export default IndexPage
